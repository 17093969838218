<template>
  <div>
    <MiniBanner />
    <div>
      <img class="zzjgImg" src="@/assets/mobile/zzjg_mobile.jpg" alt="">
    </div>
    <!-- <div class="txt txt1">
      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="color: #c10000; font-size: 14px"
          ><strong>名誉主席</strong><br
        /></span>
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)">
          李果珍&nbsp; 刘玉清&nbsp; 戴建平&nbsp; 张云亭&nbsp; 周纯武&nbsp;
          刘怀军&nbsp; 崔进国</span
        >
      </p>
      <p style="white-space: normal"><br /></p>

      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="color: #c10000; font-size: 14px"
          ><strong>大会主席</strong><br
        /></span>
      </p>
      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >陈&emsp;敏&nbsp; 金征宇&nbsp; 王振常&nbsp; 洪&emsp;楠&nbsp; 于春水&nbsp; 李&emsp;欣&nbsp; 时高峰&nbsp; 耿左军</span
        >
      </p>
      <p style="white-space: normal"><br /></p>

      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="color: #c10000; font-size: 14px"
          ><strong>执行主席</strong><br
        /></span>
      </p>
      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >于经瀛&nbsp; 宋&emsp;伟&nbsp; 鲜军舫&nbsp; 马&emsp;林</span
        >
      </p>
      <p style="white-space: normal"><br /></p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"></span>
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="color: rgb(0, 0, 0)"
          ><strong
            ><span style="color: rgb(0, 0, 0); font-size: 14px"></span></strong
        ></span>
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="color: #c10000"
          ><strong
            ><span style="font-size: 14px">大会学术委员会</span></strong
          ></span
        >
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="color: rgb(0, 0, 0); font-size: 14px"
          >委员（按姓氏笔画排序）</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >于经瀛&nbsp; 于春水&nbsp; 于&emsp;薇&nbsp; 马&emsp;林&nbsp;
          马&emsp;军&nbsp; 马&emsp;静&nbsp; 马晓海&nbsp; 马祥兴&nbsp;
          王&emsp;青&nbsp; 王&emsp;健&nbsp; 王小宜&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >王仁贵&nbsp; 王&emsp;屹&nbsp; 王怡宁&nbsp; 王贵生&nbsp; 王振常&nbsp;
          王培军&nbsp; 王光彬&nbsp; 王梅云&nbsp; 王锡明&nbsp; 方继良&nbsp;
          石建成&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >石建军&nbsp; 龙莉玲&nbsp; 卢再鸣&nbsp; 卢光明&nbsp; 卢&emsp;洁&nbsp;
          印&emsp;弘&nbsp; 冯&emsp;逢&nbsp; 冯晓源&nbsp; 范国光&nbsp;
          师光永&nbsp; 吕&emsp;滨&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >朱&emsp;力&nbsp; 朱文珍&nbsp; 朱&emsp;旭&nbsp; 朱明旺&nbsp;
          任&emsp;华&nbsp; 刘文亚&nbsp; 刘士远&nbsp; 刘长柱&nbsp; 刘玉林&nbsp;
          刘怀军&nbsp; 刘亚欧&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >刘兆玉&nbsp; 刘岳峰&nbsp; 刘挨师&nbsp; 刘爱莲&nbsp; 刘&emsp;敏&nbsp;
          刘再毅&nbsp; 刘晶哲&nbsp; 廖伟华&nbsp; 齐志刚&nbsp; 江新青&nbsp;
          安宁豫&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >孙&emsp;钢&nbsp; 孙应实&nbsp; 孙晓伟&nbsp; 孙&emsp;烨&nbsp;
          严福华&nbsp; 杜静波&nbsp; 李&emsp;欣&nbsp; 李&emsp;澄&nbsp;
          李子平&nbsp; 李天然&nbsp; 李世杰&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >李宏军&nbsp; 李坤成&nbsp; 李松柏&nbsp; 李相生&nbsp; 李绍林&nbsp;
          李晓光&nbsp; 李葆青&nbsp; 叶兆祥&nbsp; 杨正汉&nbsp; 吴&emsp;宁&nbsp;
          吴飞云&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >邱建星&nbsp; 余永强&nbsp; 沈&emsp;文&nbsp; 宋&emsp;彬&nbsp;
          宋&emsp;伟&nbsp; 宋法亮&nbsp; 查云飞&nbsp; 张&emsp;明&nbsp;
          张&emsp;敬&nbsp; 张&emsp;辉&nbsp; 张&emsp;勇</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >张云亭&nbsp; 张立苹&nbsp; 张永海&nbsp; 张伟国&nbsp; 张红梅&nbsp;
          张&emsp;青&nbsp; 张雪宁&nbsp; 张敏鸣&nbsp; 张晓琴&nbsp; 张惠茅&nbsp;
          张&emsp;滨&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >陈&emsp;敏&nbsp; 陈&emsp;雷&nbsp; 陈&emsp;峰&nbsp; 罗天友&nbsp;
          金&emsp;龙&nbsp; 金征宇&nbsp; 周&emsp;石&nbsp; 周俊林&nbsp;
          郑传胜&nbsp; 郑卓肇&nbsp; 郑晓风&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >单&emsp;鸿&nbsp; 郎&emsp;宁&nbsp; 居胜红&nbsp; 赵心明&nbsp;
          赵世华&nbsp; 钟&emsp;心&nbsp; 段&emsp;凯&nbsp; 侯&emsp;阳&nbsp;
          侯代伦&nbsp; 胡道予&nbsp; 时高峰&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >施海彬&nbsp; 姜慧杰&nbsp; 娄&emsp;昕&nbsp; 洪&emsp;楠&nbsp;
          贺&emsp;毅&nbsp; 胡春洪&nbsp; 秦乃姗&nbsp; 袁小东&nbsp; 袁建华&nbsp;
          袁新宇&nbsp; 袁慧书&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >耿左军&nbsp; 贾文霄&nbsp; 许茂盛&nbsp; 徐&emsp;克&nbsp;
          徐&emsp;冰&nbsp; 徐国生&nbsp; 徐&emsp;磊&nbsp; 高连东&nbsp;
          郭佑民&nbsp; 郭顺林&nbsp; 郭&emsp;勇&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >郭启勇&nbsp; 曹代荣&nbsp; 龚启勇&nbsp; 银&emsp;武&nbsp; 梁长虹&nbsp;
          梁宇霆&nbsp; 彭卫军&nbsp; 彭如臣&nbsp; 彭&emsp;芸&nbsp;
          彭&emsp;朋&nbsp; 蒋&emsp;涛&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >韩&emsp;丹&nbsp; 陶晓峰&nbsp; 程英升&nbsp; 程晓光&nbsp; 程流泉&nbsp;
          程敬亮&nbsp; 曾庆玉&nbsp; 曾蒙苏&nbsp; 曾献军&nbsp; 温智勇&nbsp;
          谢汝明&nbsp;
        </span>
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >谢&emsp;晟&nbsp; 满凤媛&nbsp; 蔡成仕&nbsp; 蔡剑鸣&nbsp; 薛华丹&nbsp;
          霍健伟</span
        >
      </p>
      <p>
        <br />
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px"></span>
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="color: #c10000"
          ><strong
            ><span style="font-size: 14px">大会组织委员会</span></strong
          ></span
        >
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >委员（按姓氏笔画排序）</span
        >
      </p>
      <p>
        <span style="font-size: 14px"
          ><span style="color: #000000; font-size: 14px"
            >于经瀛<span style="font-size: 14px">&nbsp; </span>于<span
              style="font-size: 14px"
              >&emsp;</span
            >薇<span style="font-size: 14px">&nbsp; </span>马<span
              style="font-size: 14px"
              >&emsp;</span
            >军 &nbsp;马<span style="font-size: 14px">&emsp;</span>林
            &nbsp;马晓海&nbsp; 王仁贵&nbsp; 王<span style="font-size: 14px"
              >&emsp;</span
            >屹&nbsp; 王怡宁&nbsp; 王贵生&nbsp; 方继良&nbsp; 邓晓涛</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >石建成<span style="font-size: 14px">&nbsp; </span>石建军<span
              style="font-size: 14px"
              >&nbsp; </span
            >卢<span style="font-size: 14px">&emsp;</span>洁 &nbsp;代静文&nbsp;
            冯<span style="font-size: 14px">&emsp;</span>逢&nbsp; 师光永&nbsp;
            吕<span style="font-size: 14px">&emsp;</span>滨&nbsp; 朱<span
              style="font-size: 14px"
              >&emsp;</span
            >旭&nbsp; 任<span style="font-size: 14px">&emsp;</span>华&nbsp;
            刘长柱&nbsp; 刘亚欧 &nbsp;</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >刘岳峰<span style="font-size: 14px">&nbsp; </span>刘<span
              style="font-size: 14px"
              >&emsp;</span
            >敏<span style="font-size: 14px">&nbsp; </span>刘晶哲&nbsp;
            齐志刚&nbsp; 安宁豫&nbsp; 孙应实&nbsp; 孙晓伟 &nbsp;孙<span
              style="font-size: 14px"
              >&emsp;</span
            >烨 &nbsp;杜静波&nbsp; 李天然 &nbsp;李世杰</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >李宏军<span style="font-size: 14px">&nbsp; </span>李相生<span
              style="font-size: 14px"
              >&nbsp; </span
            >李晓光&nbsp; 李葆青&nbsp; 杨正汉&nbsp; 吴<span
              style="font-size: 14px"
              >&emsp;</span
            >宁&nbsp; 邱建星&nbsp; 谷<span style="font-size: 14px"
              ><span style="font-size: 14px">&emsp;</span></span
            >涛&nbsp; 宋<span style="font-size: 14px"
              ><span style="font-size: 14px">&emsp;</span></span
            >伟&nbsp; 张立苹&nbsp; 张红梅</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >张&emsp;青<span style="font-size: 14px">&nbsp; </span>张<span
              style="font-size: 14px"
              >&emsp;</span
            >滨<span style="font-size: 14px">&nbsp; </span>陈<span
              style="font-size: 14px"
              >&emsp;</span
            >敏 &nbsp;陈<span style="font-size: 14px">&emsp;</span>雷
            &nbsp;金<span style="font-size: 14px">&emsp;</span>龙
            &nbsp;郑卓肇&nbsp; 郑晓风&nbsp; 郎<span style="font-size: 14px"
              >&emsp;</span
            >宁&nbsp; 赵心明&nbsp; 赵世华 &nbsp;钟<span style="font-size: 14px"
              >&emsp;</span
            >心</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >段&emsp;凯<span style="font-size: 14px">&nbsp; </span>侯代伦<span
              style="font-size: 14px"
              >&nbsp; </span
            >娄<span style="font-size: 14px">&emsp;</span>昕 &nbsp;洪<span
              style="font-size: 14px"
              >&emsp;</span
            >楠<span style="font-size: 14px">&nbsp; </span
            ><span style="color: #000000"></span>贺<span style="font-size: 14px"
              >&emsp;</span
            >毅&nbsp; 秦乃姗&nbsp; 袁小东 &nbsp;袁新宇&nbsp; 袁慧书
            &nbsp;徐文睿&nbsp; 徐<span style="font-size: 14px">&emsp;</span
            >冰</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >徐国生<span style="font-size: 14px">&nbsp; </span>徐<span
              style="font-size: 14px"
              >&emsp;</span
            >磊<span style="font-size: 14px">&nbsp; </span>高连东&nbsp; 郭<span
              style="font-size: 14px"
              >&emsp;</span
            >勇&nbsp; 梁宇霆&nbsp; 彭如臣 &nbsp;彭<span style="font-size: 14px"
              ><span style="font-size: 14px">&emsp;</span></span
            >芸&nbsp; 彭<span style="font-size: 14px">&emsp;</span>朋&nbsp;
            蒋<span style="font-size: 14px">&emsp;</span>涛&nbsp; 程晓光&nbsp;
            程流泉</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >曾庆玉&nbsp; 温智勇<span style="font-size: 14px"
              ><span style="font-size: 14px">&nbsp; </span></span
            >谢汝明<span style="font-size: 14px">&nbsp; </span>谢<span
              style="font-size: 14px"
              >&emsp;</span
            >晟<span style="font-size: 14px">&nbsp; </span>满凤媛<span
              style="font-size: 14px"
              >&nbsp; </span
            >蔡成仕<span style="font-size: 14px">&nbsp; </span>蔡剑鸣<span
              style="font-size: 14px"
              >&nbsp; </span
            >鲜军舫<span style="font-size: 14px">&nbsp; </span>薛华丹<span
              style="font-size: 14px"
              >&nbsp; </span
            >霍健伟</span
          ><br />
        </span>
      </p>
      <p>
        <span style="font-size: 14px"></span
        ><span style="font-size: 14px"></span
        ><span style="font-size: 14px"></span>
      </p>
      <p>
        <br />
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"></span>
      </p>
      <p style="line-height: 2em; text-align: justify; font-size: 14px">
        <strong style="color: #c10000">大会秘书</strong>
      </p>
      <p
        style="
          white-space: normal;
          font-size: 14px;
          line-height: 2em;
          text-align: justify;
        "
      >
        <span style="color: rgb(0, 0, 0)"
          >邓晓涛&nbsp; 谷&emsp;涛&nbsp; 徐文睿&nbsp; 代静文</span
        >
      </p>
      <p style="white-space: normal"><br /></p>
    </div> -->
  </div>
</template>

<script>
import MiniBanner from "@/components/miniBanner/index";
import { mapActions } from "vuex";

export default {
  name: "MiniOrganization",
  components: {
    MiniBanner,
  },
  methods: {
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    this.setTitle("组织架构");
  },
};
</script>

<style scoped>
.zzjgImg {
  width: 100%;
}
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 2em;
  padding: 25px 30px;
}
</style>